import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'job'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {}
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        ...crud.getters,
        grades: () => {
            return _.map(_.keys(Array(11)), (i) => {
                i = _.parseInt(i);
                return {
                    value: i + 1,
                    text: (i + 1) + ' кл.'
                };
            })
        }
    }
}