import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
import { JOB_SERVER } from '@/servers'
const crud = new Crud({
    module: 'media_library'
})

export default {
    namespaced: true,
    state: {},
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async media(context, data) {
            if (!data.fields) {
                data.fields = ['title', 'path', 'type'].join(',')
            }
            return await ApiHelper.get(`${ JOB_SERVER }/api/media-library`, data);
        },
        async upload(context, data) {
            const fields = ['title', 'path'].join(',')
            return await ApiHelper.post(`${ JOB_SERVER }/api/media-library/upload?fields=${fields}`, data);
        },
        async replace(context, data) {
            return await ApiHelper.post(`${ JOB_SERVER }/api/media-library/replace`, data);
        },
    },
    getters: {
        ...crud.getters
    }
}