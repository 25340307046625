// import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'interactives_instruction'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {},
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        ...crud.getters
    }
}