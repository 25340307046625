import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
import { JOB_SERVER } from '@/servers'

const crud = new Crud({
    module: 'content_kit'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {},
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async create(context, data) {
            return await ApiHelper.post(`${ JOB_SERVER }/api/content-kit/create`, data);
        },
        async update(context, {id, payload}) {
            return await ApiHelper.put(`${ JOB_SERVER }/api/content-kit/update?id=${id}`, payload);
        }
    },
    getters: {
        ...crud.getters
    }
}