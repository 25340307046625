// import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'hint'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {
            type: null,
            name: null
        },
        filterRules: {
            name: (value) => ({ like: value })
        }
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        ...crud.getters
    }
}