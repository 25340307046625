import _ from 'lodash'
import ApiHelper from '@/helpers/ApiHelper.js'
import { JOB_SERVER } from '@/servers'

export default class {
    constructor(options) {
        const API_MODULE = options.module.replaceAll('_', '-');
        
        this.state = {
            filter: {},
            grid: {
                index: {
                    options: {
                        page: 1,
                        itemsPerPage: 10
                    }
                }
            }
        };
        this.mutations = {
            SET_FILTER(state, data) {
                state.filter = data;
            },
            RESET_FILTER(state) {
                state.filter = _.mapValues(state.filter, () => {
                    return null;
                })
            },
            UPDATE_GRID_OPTIONS(state, data) {
                _.assign(state.grid, data);
            }
        };
        this.actions = {
            filter({commit}, data) {
                commit('SET_FILTER', data);
                return true;
            },
            reset({commit}) {
                commit('RESET_FILTER');
                return true;
            },
            async one(context, data) {
                return ApiHelper.get(`${JOB_SERVER}/api/${API_MODULE}/one`, data);
            },
            async list(context, data) {
                return ApiHelper.get(`${JOB_SERVER}/api/${API_MODULE}`, data);
            },
            async fetch({ dispatch }, data) { // Alias to list
                dispatch('list', data)
            },
            async create(context, data) {
                return await ApiHelper.post(`${JOB_SERVER}/api/${API_MODULE}/create`, data);
            },
            async update(context, data) {
                return await ApiHelper.put(`${JOB_SERVER}/api/${API_MODULE}/update`, data, {
                    id: _.get(data, 'id', 0)
                });
            },
            async delete(context, data) {
                return await ApiHelper.delete(`${JOB_SERVER}/api/${API_MODULE}/delete`, data, {
                    id: _.get(data, 'id', 0)
                });
            },
            updateGridOptions({commit}, data) {
                commit('UPDATE_GRID_OPTIONS', data);
                return true;
            }
        };
        this.getters = {
            
            filterHasValues (state) {

                const filterKeys = Object.keys(state.filter)
                const filterFilledKeys = filterKeys.filter((key) => state.filter[key] !== null && state.filter[key] !== '')
                
                return filterFilledKeys.length > 0
            }
        }
    }
}