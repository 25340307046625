export default [
    {
        module: 'task',
        //     Кто/Что      Кого/Чего
        name: ['задания', 'задания']
    },
    {
        module: 'theme',
        name: ['темы', 'тем']
    },
    {
        module: 'user',
        name: ['пользователи', 'пользователя']
    },
    {
        module: 'job',
        name: ['работы', 'работы']
    },
    {
        module: 'hint',
        name: ['инструкции', 'инструкцию']
    },
    {
        module: 'scoring-criteria',
        name: ['критерии оценивания', 'критерия оценивания']
    },
    {
        module: 'interactives-instruction',
        name: ['инструкции интерактивов', 'инструкции интерактива']
    },
    {
        module: 'keyboard-list',
        name: ['листы вирт. клавиатуры', 'листа вирт. клавиатуры']
    },
    // {
    //     module: 'content-kit',
    //     name: ['наборы контента', 'наборов контента']
    // },
    {
        module: 'lesson-plan',
        name: ['поурочное планирование', 'поурочного планирования']
    }
]